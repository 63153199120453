import React from 'react';
import { Film, Tv, Music, TrendingUp } from 'lucide-react';

const MiscContent = () => {
    const sections = [
        {
            title: "Movies",
            icon: <Film className="w-5 h-5 text-red-400" />,
            items: [
                "Memento",
                "No Country for Old Men",
                "Hateful Eight",
                "Tenet",
                "Grand Budapest Hotel"
            ],
            subtitles: [
                "Christopher Nolan",
                "Coen Brothers",
                "Quentin Tarantino",
                "Christopher Nolan",
                "Wes Anderson"
            ]
        },
        {
            title: "TV Shows",
            icon: <Tv className="w-5 h-5 text-red-400" />,
            items: [
                "Game of Thrones",
                "Better Caul Saul",
                "Succession",
                "Breaking Bad",
                "Atlanta"
            ]
        },
        {
            title: "Songs",
            icon: <Music className="w-5 h-5 text-red-400" />,
            items: [
                "PRIDE",
                "Bad Religion",
                "Money Trees",
                "Restore the Feeling",
                "Mey Mista",
                "Super Rich Kids",
                "Wurli",
                "People Under The Stairs",
                "See You Again",
                "Shame On The Devil",
            ],
            subtitles: [
                "Kendrick Lamar",
                "Frank Ocean",
                "Kendrick Lamar",
                "Daniel Caesar",
                "Isaiah Rashad",
                "Frank Ocean",
                "Dominic Fike",
                "Mac Miller",
                "Tyler the Creator",
                "Vince Staples",
            ]
        },
        {
            title: "Players I'm Buying Stock In (11/21)",
            icon: <TrendingUp className="w-5 h-5 text-red-400" />,
            items: [
                "Brandon Miller",
                "Dyson Daniels",
                "Bilal Coulibaly",
                "Naji Marshall",
                "Aaron Wiggins",
                "Tari Eason",
                "Ty Jerome",
                "Keon Ellis",
                "Santi Aldama",
                "Zaire Williams",
            ]
        }
    ];

    return (
        <div className="max-w-4xl mx-auto py-8 px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {sections.map((section, idx) => (
                    <div key={idx} className="bg-gray-900 rounded-lg p-6 transition-colors">
                        <div className="flex items-center space-x-2 mb-4 border-b border-gray-700 pb-2">
                            {section.icon}
                            <h3 className="text-xl font-bold text-white">
                                {section.title}
                            </h3>
                        </div>
                        <div className="space-y-2">
                            {section.items.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex items-center space-x-2 text-gray-300 hover:text-red-400 transition-colors cursor-pointer"
                                >
                                    <span className="text-red-400 text-sm">{(index + 1).toString().padStart(2, '0')}</span>
                                    <div>
                                        <span className="font-medium">{item}</span>
                                        {section.subtitles && section.subtitles[index] && (
                                            <span className="text-gray-500 text-sm ml-2">| {section.subtitles[index]}</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MiscContent;